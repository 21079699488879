import React from "react";
import SEO from "../components/atoms/seo";
import "../styles/pages/_thank-you-new.scss"
import {
  Logo,
  Hero,
  CardA,
  CardB,
  CardC,
  im1,
  im2,
  im3,
  im4,
  stars,
  ba,
  guarantee,
  phone
} from "../images/thank-you-details"
import CountdownTimer from "../components/atoms/countdown";
const ThankYouNEWPage = () => (

  <main className="co-thank-you-page">
    <SEO title="Thank you" />

    {/* <header style={{backgroundImage: `url(${Hero})`}}> */}
    <header>
      <figure className="co-thank-you-page__logo">
        <img src={Logo} alt="logo" />
      </figure>

      <div className="co-thank-you-page__hero">
        <div className="co-thank-you-page__hero-container">

          <div className="co-thank-you-page__counter-section">

            <div className="co-thank-you-page__counter-section-copy">
              <div className="co-thank-you-page__flex">
                <img src={phone} alt="logo" />
                <h2>We'll call you!</h2>
              </div>
              <p>Be ready for a call from one of our experts in: </p>
            </div>

            <div className="co-thank-you-page__counter-section-timer">
              <CountdownTimer />
            </div>
          </div>
          <div className="co-thank-you-page__call-section">
            <p>Or give us a call here: <a href="tel:9545194658">954-519-4658</a></p>
          </div>
          <div className="co-thank-you-page__service-area">
            <div>
              <h2>Great News!</h2>
              <p>We service your area.</p>

            </div>
          </div>
        </div>
      </div>


    </header>

    <section className="co-thank-you-page__section">
      <div className="co-thank-you-page__trust-section">
        <div className="co-thank-you-page__trust-section-logos">
            <img src={im1} />
            <img src={im2} />
            <img src={im3} />
        </div>
        <p>With nearly 30 years of experience, Demings is one of the most trusted floor restoration companies in South Florida.</p>
      </div>

    </section>
    <section className="co-thank-you-page__section">
      <div className="co-thank-you-page__reviews-section">

        <h3>Thousands of Happy Customers</h3>
        <h4 className="subtitle">Here’s what some of them had to say</h4>

        <div className="co-thank-you-page__cards-container reviews-section">
          <div className="review">
            <figure>
              <img src={stars} />
            </figure>

            <p>Joe did an excellent job with cleaning my tile floors to look like new again! He is very personable and walked me through the whole process. He really seems to take pride in his work and wants the customer to be completely satisfied. Great job Joe!</p>
            <span>- Cathryn</span>
          </div>
          <div className="review">
            <figure>
              <img src={stars} />
            </figure>

            <p>Joe was not just on time, he was early. Was extremely careful with our possessions and did a fantastic job. Floors look like new. I highly recommend Demings carpet and tile cleaning.</p>
            <span>- Maressa</span>
          </div>
          <div className="review">
            <figure>
              <img src={stars} />
            </figure>

            <p>Honestly I never write reviews cause I forget but when someone like Joe does what he does than the impression left on me from his personality and workmanship I said I have to take a few min to write him a review. The dude rocks!!! Thanks Joe!!!</p>
            <span>- Kevin</span>
          </div>

        </div>


      </div>

    </section>
    
  </main>
)

export default ThankYouNEWPage;
